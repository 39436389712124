// extracted by mini-css-extract-plugin
export var about = "about-module--about--34f68";
export var about_image = "about-module--about_image--bbd34";
export var about_section = "about-module--about_section--68343";
export var back_text = "about-module--back_text--8c646";
export var banner_content = "about-module--banner_content--6cdd5";
export var cards = "about-module--cards--1f00b";
export var home_container = "about-module--home_container--71bb8";
export var service_heading = "about-module--service_heading--d8808";
export var services = "about-module--services--d104a";
export var since = "about-module--since--d333a";
export var teams = "about-module--teams--f4158";
export var text_uppercase = "about-module--text_uppercase--79145";